import React, {MouseEventHandler} from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./ui.css";
import {ButtonVariant} from "react-bootstrap/types";

interface IMyButtonProps {
    text: string;
    onClick?: MouseEventHandler<HTMLElement>;
    offset?: number;
    variant?: ButtonVariant;
    disabled?: boolean;
}

function MyButton(props: IMyButtonProps) {
    return <Col xs={{span: 12}} md={{span: 2, offset: props.offset}}>
        <Button size="lg" className="full-width" onClick={props.onClick} variant={props.variant} disabled={props.disabled}>
            {props.text}
        </Button>
    </Col>;
}

export default MyButton;
