import React from 'react';
import Reader from "./reader/Reader";
import Container from "react-bootstrap/Container";
import MyNavbar from "./MyNavbar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Liked from "./reader/Liked";
import Login from "./Login";
import Logout from "./Logout";
import User from "./User";
import Stats from "./reader/stats/Stats";


interface IMyRouteProps {
    isLoggedIn: boolean;
    children?: JSX.Element | JSX.Element[];
}

function MyRoute(props: IMyRouteProps) {
    return <>
        <MyNavbar isLoggedIn={props.isLoggedIn}/>
        <Container>
            {props.children}
        </Container>
    </>;
}

function App() {
    return <BrowserRouter>
        <Routes>
            <Route
              path="/login"
              element={
                <MyRoute isLoggedIn={false}>
                  <Login/>
                </MyRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <MyRoute isLoggedIn={false}>
                  <Logout/>
                </MyRoute>
              }
            />
            <Route
              path="/liked"
              element={
                <MyRoute isLoggedIn={true}>
                  <Liked/>
                </MyRoute>
              }
            />
            <Route
              path="/stats"
              element={
                <MyRoute isLoggedIn={true}>
                  <Stats/>
                </MyRoute>
              }
            />
            <Route
              path="/user"
              element={
                <MyRoute isLoggedIn={true}>
                  <User/>
                </MyRoute>
              }
            />
            <Route
              path="/"
              element={
                <MyRoute isLoggedIn={true}>
                  <Reader/>
                </MyRoute>
              }
            />
        </Routes>
    </BrowserRouter>;
}

export default App;
