import React, {useCallback, useEffect, useState} from "react";
import {IStory} from "./models";
import {apiGetLiked} from "./apis";
import Stories from "./Stories";

function Liked() {
    const [stories, setStories] = useState<IStory[]>([]);

    const reloadReader = useCallback(() => {
        apiGetLiked()
            .then(setStories)
    }, []);

    useEffect(reloadReader, [reloadReader]);

    return <>
        <Stories stories={stories} reloadReader={reloadReader} showReadButtons={false}/>
    </>;
}

export default Liked;
