import React, {useEffect, useState} from "react";
import {apiGetUser, apiUpdateUser} from "./apis";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormStringField from "./FormStringField";
import Row from "react-bootstrap/Row";
import MyButton from "./ui/MyButton";
import SuccessErrorModal from "./ui/SuccessErrorModal";

function User() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        apiGetUser()
            .then(user => {
                setEmail(user.email);
                setName(user.name);
            })
    }, []);

    const handleSubmit = () => {
        setPending(true);
        setError(false);
        setSuccess(false);
        apiUpdateUser({email, name, password})
            .then(
                () => setSuccess(true),
                () => setError(true)
            )
            .finally(() => setPending(false));
    }

    return <Container>
        <Form>
            <FormStringField name="email" value={email} setValue={setEmail}/>
            <FormStringField name="name" value={name} setValue={setName}/>
            <FormStringField name="password" value={password} setValue={setPassword}/>
            <Row>
                <MyButton text="Submit" onClick={handleSubmit} offset={10} disabled={pending}/>
            </Row>
            <SuccessErrorModal success={success} error={error}/>
        </Form>
    </Container>;
}

export default User;
