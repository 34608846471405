import Container from "react-bootstrap/Container";
import {apiLogout} from "./apis";

function Logout() {
    apiLogout()
        .then(() => {
            window.location.href = '/login';
        });

    return <Container>
        <h3>Logging out</h3>
    </Container>;
}

export default Logout;
