import React, {useState} from "react";
import FormStringField from "./FormStringField";
import Container from "react-bootstrap/Container";
import MyButton from "./ui/MyButton";
import Form from "react-bootstrap/Form";
import {apiLogin} from "./apis";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [pending, setPending] = useState(false);

    const handleSubmit = () => {
        setPending(true);
        apiLogin(email, password)
            .finally(() => setPending(false))
            .then(() => {
                window.location.href = '/';
            }, () => setError(true));
    }

    return <Container>
        <Form>
            <FormStringField name="email" value={email} setValue={setEmail}/>
            <FormStringField name="password" value={password} setValue={setPassword}/>
            <Row>
                <MyButton text="Submit" onClick={handleSubmit} offset={10} disabled={pending}/>
            </Row>
            {error &&
            <Row>
                <Col>
                    <Alert variant="warning">Access denied</Alert>
                </Col>
            </Row>
            }
        </Form>
    </Container>;
}

export default Login;
