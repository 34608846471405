import myAxios from "./myAxios";
import axios from "axios";

interface ICsrfResponse {
    csrf: string;
}

interface IUser {
    email: string;
    name: string;
}

interface IUserWithPassword extends IUser {
    password: string;
}

const loginAxios = axios.create();

export function apiGetCsrf(): Promise<void> {
    return myAxios.get<ICsrfResponse>('/api/csrf')
        .then(res => {
            myAxios.defaults.headers.common['X-CSRFToken'] = res.data.csrf;
            loginAxios.defaults.headers.common['X-CSRFToken'] = res.data.csrf;
        });
}

export function apiLogin(email: string, password: string): Promise<void> {
    return apiGetCsrf().then(() =>
        loginAxios.post<void>('/api/login', {email, password})
            .then(res => res.data)
    );
}

export function apiLogout(): Promise<void> {
    return apiGetCsrf().then(() =>
        myAxios.post<void>('/api/logout')
            .then(res => res.data)
    );
}

export function apiUpdateUser(user: IUserWithPassword): Promise<void> {
    return apiGetCsrf().then(() =>
        myAxios.put<void>('/api/user', user)
            .then(res => res.data)
    );
}

export function apiGetUser(): Promise<IUser> {
    return apiGetCsrf().then(() =>
        myAxios.get<IUser>('/api/user')
            .then(res => res.data)
    );
}
