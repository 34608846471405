import Left from "./Left";
import LastUpdated from "./LastUpdated";
import React from "react";

function Stats() {
    return <>
        <h3>Left</h3>
        <Left/>

        <hr/>

        <h3>Last updated</h3>
        <LastUpdated/>
    </>;
}

export default Stats;
