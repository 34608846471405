import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {LinkContainer} from "react-router-bootstrap";
import ReaderNavbar from "./reader/ReaderNavbar";

interface IMyNavbarProps {
    isLoggedIn: boolean;
}

function MyNavbar(props: IMyNavbarProps) {
    return <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
            <LinkContainer to="/">
                <Navbar.Brand>PBreader</Navbar.Brand>
            </LinkContainer>
            {props.isLoggedIn &&
            <>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer to="/">
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>

                        <ReaderNavbar/>
                        
                        <LinkContainer to="/user">
                            <Nav.Link>User</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/logout">
                            <Nav.Link>Log out</Nav.Link>
                        </LinkContainer>

                    </Nav>
                </Navbar.Collapse>
            </>
            }
        </Container>
    </Navbar>
}

export default MyNavbar;