import {LinkContainer} from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import React, {useEffect, useState} from "react";
import {apiGetSites} from "./apis";
import { useSearchParams } from "react-router-dom";

function ReaderNavbar() {
    const [sites, setSites] = useState<string[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        apiGetSites()
            .then(setSites)
    }, []);

    const updateSearchParam = (name: string, value: string) => {
        searchParams.set(name, value);
        setSearchParams(searchParams);
    };

    const removeSearchParam = (name: string) => {
        searchParams.delete(name);
        setSearchParams(searchParams);
    };

    return <>
        <LinkContainer to="/liked">
            <Nav.Link>Liked</Nav.Link>
        </LinkContainer>
        <NavDropdown title="Size" id="basic-nav-dropdown">
            {
                [1, 3, 10, 100, 200].map(limit =>
                    <NavDropdown.Item key={limit} onClick={() => updateSearchParam('limit', '' + limit)}>{limit}</NavDropdown.Item>
                )
            }
        </NavDropdown>
        <NavDropdown title="Filter" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => removeSearchParam('site')}>ALL</NavDropdown.Item>
            {
                sites.map(site =>
                    <NavDropdown.Item key={site} onClick={() => updateSearchParam('site', site)}>{site}</NavDropdown.Item>
                )
            }
        </NavDropdown>
        <LinkContainer to="/stats">
            <Nav.Link>Stats</Nav.Link>
        </LinkContainer>
    </>;
}

export default ReaderNavbar;