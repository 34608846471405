import {Dispatch, SetStateAction} from "react";
import {FormControl, FormGroup} from "react-bootstrap";

interface IFormFieldProps {
    name: string;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
}

function FormStringField(props: IFormFieldProps) {
    return <FormGroup>
        <label htmlFor={props.name}>{props.name}</label>
        <FormControl
            type={props.name}
            id={props.name}
            value={props.value}
            onChange={event => props.setValue(event.target.value)}
        />
    </FormGroup>;
}

export default FormStringField;