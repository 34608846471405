import myAxios from "../myAxios";
import {IReadStory, IStory, IStoryMeta} from "./models";
import {apiGetCsrf} from "../apis";

export function apiGetRead(limit?: number, site?: string): Promise<IStory[]> {
    return myAxios.get<IStory[]>('/api/read', {params: {limit, site}})
        .then(res => res.data);
}

export function apiGetLiked(limit?: number): Promise<IStory[]> {
    return myAxios.get<IStory[]>('/api/liked', {params: {limit}})
        .then(res => res.data);
}

export function apiGetSites(): Promise<string[]> {
    return myAxios.get<string[]>('/api/sites')
        .then(res => res.data);
}

export function apiMarkRead(stories: IReadStory[]): Promise<void> {
    return apiGetCsrf().then(() =>
        myAxios.post<void>('/api/read', {stories})
            .then(res => res.data)
    );
}

export function apiBack(): Promise<void> {
    return apiGetCsrf().then(() =>
        myAxios.post<void>('/api/back')
            .then(res => res.data)
    );
}

export function apiUpdate(): Promise<void> {
    return apiGetCsrf().then(() =>
        myAxios.post<void>('/api/update')
            .then(res => res.data)
    );
}

export function apiGetLeft(): Promise<Map<string, number>> {
    return myAxios.get<Map<string, number>>('/api/count')
        .then(res => res.data);
}

export function apiGetLastUpdated(): Promise<IStoryMeta[]> {
    return myAxios.get<IStoryMeta[]>('/api/last-updated')
        .then(res => res.data);
}
