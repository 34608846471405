import React from "react";
import {IStory} from "./models";
import Story from "./Story";

interface IStoriesProps {
    stories?: IStory[];
    reloadReader: () => void;
    showReadButtons: boolean;
}

function Stories(props: IStoriesProps) {
    if (props.stories == null) {
        return <h3>Loading</h3>;
    }

    if (props.stories.length < 1) {
        return <h3>No stories</h3>;
    }

    const storyComponents = props.stories.map(story =>
        <Story key={story.storyId} story={story} reloadReader={props.reloadReader} showReadButtons={props.showReadButtons}/>
    );

    return <div>{storyComponents}</div>;
}

export default Stories;