import React from 'react'
import {IStory} from "./models";
import {apiMarkRead} from "./apis";
import Row from "react-bootstrap/Row";
import MyButton from "../ui/MyButton";

interface IStoryProps {
    story: IStory;
    reloadReader: () => void;
    showReadButtons: boolean;
}

const Story = (props: IStoryProps) => {
    const story = props.story;

    const markRead = (liked: boolean) => {
        apiMarkRead([{storyId: story.storyId, liked}])
            .then(props.reloadReader);
    }

    return <div>
        <div dangerouslySetInnerHTML={{__html: story.content}}/>
        <a href={story.link} target="_blank" rel="noreferrer">{story.storyId}</a>
        {story.site === 'xkcd.com' && <>
            <br />
            <a href={story.link.replace('xkcd.com', 'explainxkcd.com')} target="_blank" rel="noreferrer">Explain</a>
        </>}
        {props.showReadButtons &&
            <Row>
                <MyButton text="Like" onClick={() => markRead(true)} offset={8} variant="success"/>
                <MyButton text="Read" onClick={() => markRead(false)}/>
                {navigator.share && <MyButton text="Share" onClick={() => navigator.share({text: story.content})}/>}
            </Row>
        }
        <hr/>
    </div>
};

export default Story
