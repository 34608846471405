import axios from "axios";

const myAxios = axios.create();

myAxios.interceptors.response.use(response => response, error => {
    if (error?.response?.status === 401) {
        window.location.href = '/login';
    }

    if (error.response) {
        alert(`Error response received for ${error.config.method} ${error.config.url} - errorCode: ${error.response.status} payload: ${error.response.data}`);
    } else {
        alert(`Error received when making ${error.config.method} ${error.config.url} - ${error}`);
    }

    return Promise.reject(error);
});

export default myAxios;
