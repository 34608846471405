import React, {useEffect, useState} from "react";
import {apiGetLastUpdated} from "../apis";
import {IStoryMeta} from "../models";
import Table from "react-bootstrap/Table";

function LastUpdated() {
    const [lastUpdated, setLastUpdated] = useState([] as IStoryMeta[]);

    useEffect(() => {
        apiGetLastUpdated()
            .then(setLastUpdated)
    }, []);

    return <Table>
        <thead>
        <tr>
            <th>Site</th>
            <th>Link</th>
            <th>Time</th>
        </tr>
        </thead>
        <tbody>{
            lastUpdated.map(story => <tr key={story.storyId}>
                <td>{story.site}</td>
                <td>
                    <a href={story.link} target="_blank" rel="noreferrer">{story.link}</a>
                </td>
                <td>{story.time}</td>
            </tr>)
        }</tbody>
    </Table>;
}

export default LastUpdated;
