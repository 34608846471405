import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import React from "react";

interface ISuccessErrorModal {
    success: boolean;
    error: boolean;
}

function SuccessErrorModal(props: ISuccessErrorModal) {
    return <>
        {props.success &&
        <Row>
            <Col>
                <Alert variant="success">Update succeeded</Alert>
            </Col>
        </Row>
        }
        {props.error &&
        <Row>
            <Col>
                <Alert variant="warning">Update failed</Alert>
            </Col>
        </Row>
        }
    </>;
}

export default SuccessErrorModal;