import React, {useEffect, useState} from "react";
import {apiGetLeft} from "../apis";

function Left() {
    const [left, setLeft] = useState({});

    useEffect(() => {
        apiGetLeft()
            .then(setLeft)
    }, []);

    return <div>{
        Object.entries(left).map(([site, count]) => {
                return <div key={site}>{site}: {count}</div>;
            }
        )
    }</div>;
}

export default Left;
